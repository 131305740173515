<template>
  <div>
    <template>
      <v-dialog v-model="dialog" max-width="450" height="50vh" persistent>
        <v-card>
          <v-card-title class="justify-center">La sesión está a punto de expirar</v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col>
                <div class="text-center">
                  <span>¿Desea mantener la sesión iniciada?</span>
                  <br>
                  <span>De lo contrario, será redirigido para volver a iniciar sesión</span>
                  <br>
                  <br>
                </div>
                <div class="text-center">
                  <v-btn class="ml-3" color="primary" @click="acceptDialog()">Aceptar</v-btn>
                  <v-btn class="ml-3" color="error" @click="cancelDialog()">Cerrar sesión</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <Novedades ref="dNovedades"></Novedades>
    </template>
    <v-app-bar app clipped-left flat light style="height: 50px;margin-top: -5px;transform: translateY(0px);left: 0px;right: 0px;">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <img src="../assets/LogoDSS.png" alt="DSS" height="30">
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon v-if="showCerrarSesion" @click="mostrarNovedades" class="mr-5">mdi-creation</v-icon>
      <a class="a-href" @click="logout" v-if="showCerrarSesion">
        Cerrar sesión
      </a>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app clipped flat v-bind:width="250" temporary v-if="showSideBar" style="top: 45px; max-height: 100%">
      <v-list dense>
        <v-list-item link :to="'/home'">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title>SEIA</v-list-item-title>
          </template>
          <v-list-group
            :value="false"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Evaluación Ambiental</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="ea in itemsEvaluacionAmbiental"
              :key="ea.title"
              :to="ea.link"
              link
            >
                <v-list-item-icon>
                  <v-icon>{{ea.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ ea.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ ea.subtitulo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Consultas de Pertinencia</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="cp in itemsConsultaPertinencia"
              :key="cp.title"
              :to="cp.link"
              link
            >
                <v-list-item-icon>
                  <v-icon>{{cp.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ cp.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ cp.subtitulo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Otros</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="otrosSeia in itemsOtrosSeia"
              :key="otrosSeia.title"
              :to="otrosSeia.link"
              link
            >
                <v-list-item-icon>
                  <v-icon>{{otrosSeia.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ otrosSeia.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ otrosSeia.subtitulo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-group
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title>SNIFA</v-list-item-title>
          </template>
          <v-list-group
            :value="false"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Procesos sancionatorios</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="ps in itemsProcesosSancionatorios"
              :key="ps.title"
              :to="ps.link"
              link
            >
                <v-list-item-icon>
                  <v-icon>{{ps.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ ps.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ ps.subtitulo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Otros</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="otrosSnifa in itemsOtrosSnifa"
              :key="otrosSnifa.title"
              :to="otrosSnifa.link"
              link
            >
                <v-list-item-icon>
                  <v-icon>{{otrosSnifa.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ otrosSnifa.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ otrosSnifa.subtitulo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-group :value="expand_locations" v-if="superUser">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">Administración</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group color="primary">
              <v-list-item v-for="administracion in itemsAdministracion" :key="administracion.title" :to="administracion.link" link>
                <v-list-item-icon>
                  <v-icon>{{administracion.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ administracion.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import axios from 'axios'
  import Novedades from './Novedades.vue';
  export default {
    components: {
      Novedades
    },
    watch: {
      $route(to, from) {
        if (to.path.includes("/busqueda") || to.path.includes("/evaluacion") || to.path.includes("/avanzada")) {
          this.expand_locations = true;
        } else {
          this.expand_locations = false;
        }
      },
    },
    data: () => ({
      expand_locations:false,
      drawer: true,
      itemsEvaluacionAmbiental: [
        {
          title: "Buscador de Proyectos SEIA",
          link: "/busqueda",
          icon: "mdi-file-document",
          props: {
            active: false,
          },
        },
        {
          title: "Buscador de Documentos",
          link: "/evaluacion",
          icon: "mdi-file",
          props: {
            active: false,
          },
        },
        {
          title: "Buscador de textos en documentos claves",
          link: "/avanzada",
          icon: "mdi-file-find",
          props: {
            active: false,
          },
        },
      ],
      itemsConsultaPertinencia: [
        {
          title: "Consultas de Pertinencia",
          link: "/busqueda/pertinencia",
          icon: "mdi-file-document",
          props: {
            active: false,
          },
        },
        {
          title: "Texto en resoluciones",
          link: "/avanzada-pertinencia",
          icon: "mdi-file-find",
          props: {
            active: false,
          },
        },
      ],
      itemsOtrosSeia: [
        {
          title: "Guías y Criterios",
          link: "/guias-sea",
          icon: "mdi-file-find",
          props: {
            active: false,
          },
        },
        /*{
          title: "Mapa de proyectos",
          link: "/mapa",
          icon: "mdi-google-maps",
          props: {
            active: false,
          },
        }*/
      ],
      intervalSesion: null,
      timeOut2: null,
      dialog: false,
      timeAlert: 30000,
      showSideBar: false,
      showCerrarSesion: false,
      itemsProcesosSancionatorios: [
        {
          title: "Buscador de Procesos Sancionatorios",
          link: "/busqueda/snifa",
          icon: "mdi-file-document",
          props: {
            active: false,
          },
        },
        {
          title: "Buscador de Hechos",
          link: "/busqueda/hechos",
          icon: "mdi-file-document",
          props: {
            active: false,
          },
        },
        {
          title: "Buscador de textos en documentos claves",
          link: "/avanzada-snifa",
          icon: "mdi-file-find",
          props: {
            active: false,
          },
        }
      ],
      itemsOtrosSnifa: [
      {
          title: "Instrucciones y Requerimientos",
          link: "/guias-snifa",
          icon: "mdi-file-document",
          props: {
            active: false,
          },
        }
      ],
      superUser: false,
      drawer: false,
      itemsAdministracion: [
        {
          title: "Códigos empresas",
          link: "/administracion/empresas",
          icon: "mdi-city",
          props: {
            active: false
          }
        },
        {
          title: "Usuarios",
          link: "/administracion/usuarios",
          icon: "mdi-account",
          props: {
            active: false
          }
        },
        {
          title: "Ingreso",
          link: "/administracion/ingresos",
          icon: "mdi-login",
          props: {
            active: false
          }
        },
        {
          title: "Novedades",
          link: "/administracion/novedades",
          icon: "mdi-newspaper",
          props: {
            active: false
          }
        }
      ],
      showNotificaciones: true,
    }),
    methods: {
      logout() {
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refresh-token')
        if(token) {
          axios.post(process.env.VUE_APP_BACKEND_URL + '/logout/', { refresh_token: refreshToken })
            .then(() => {
              localStorage.removeItem('token');
              localStorage.removeItem('refresh-token');
              localStorage.removeItem('exp_token');
              window.location.href = '/';
            })
            .catch(error => {
              console.error('Error al cerrar sesión:', error);
            });
        }
      },
      validarToken() {
        const exp_token = parseInt(localStorage.getItem('exp_token')) * 1000
        const dateToken = new Date(exp_token);
        const hora = new Date().getTime();
        let currentDate = new Date(hora)
        if (currentDate >= dateToken) {
          this.cancelDialog()
        }
      },
      alertaSesion() {
        this.intervalSesion = setInterval(() => {
          const hora = new Date().getTime();
          const horaActual = new Date(hora)
          const exp_token = localStorage.getItem('exp_token') * 1000
          const dateToken = new Date(exp_token);
          const hora_aviso = dateToken-this.timeAlert
          if (horaActual >= hora_aviso) {
            this.dialog = true;
            this.pauseTimer();
            this.timeOut2 = setTimeout(() => {
              this.cancelDialog()
            }, 25000);
          }
        }, 1000);
      },
      cancelDialog() {
        this.dialog = false
        clearTimeout(this.timeOut2);
        setTimeout(() => {
          this.logout()
        },100);
      },
      acceptDialog() {
        const params = {
          refresh: localStorage.getItem('refresh-token'),
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + '/api/token/refresh/', params).then(response => {
          const newToken = response.data.access;
          const newTokenExp = response.data.exp_token
          localStorage.setItem('token', newToken)
          localStorage.setItem('exp_token', newTokenExp)
          clearTimeout(this.timeOut2);
        }).catch(error => {
          console.error(error);
        }).finally(() => {
          this.validarToken()
          this.dialog = false;
          this.alertaSesion()
        });
      },
      pauseTimer() {
        clearInterval(this.intervalSesion); // Detener el intervalo
      },
      mostrarNovedades() {
        this.$refs.dNovedades.open()
      }
    },
    watch: {
      '$route'(to, from) {
        if(to.name != 'login' && to.name != 'registro' && to.name != 'verificar-correo' && to.name != 'nueva-contrasena' && to.name != 'cambio-contrasena') {
          this.validarToken()
          this.alertaSesion()
          this.showSideBar = true
          this.showCerrarSesion = true
          if (localStorage.getItem('super') == 'true') {
            this.superUser = true
          }
        } else {
          this.showSideBar = false
          this.showCerrarSesion = false
        }
      }
    },
    mounted() {
      if (this.$route.path != '/') {
        this.alertaSesion()
      }
    }
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
      margin-right: 20px;
  }
  .titleCard {
    color: #243244;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
  }
  .a-href {
    color: rgb(253, 0, 0) !important;
    caret-color: #ff0000 !important;
  }
  .v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group>.v-list-group__items>.v-list-item {
    padding-left: 35px;
  }
</style>